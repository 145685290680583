import React from "react";
import Ethereum from '../Static/imgs/portfolio/Ethereum.png'
import Merlin from '../Static/imgs/portfolio/Merlin.png'
import Bouncebit from '../Static/imgs/portfolio/Bouncebit.png'
import Zklink from '../Static/imgs/portfolio/Zklink.png'
import TRON from '../Static/imgs/portfolio/TRON.png'
import Lido from '../Static/imgs/portfolio/Lido.png'
import EigenLayer from '../Static/imgs/portfolio/EigenLayer.png'
import EtherFi from '../Static/imgs/portfolio/EtherFi.png'
import Puffer from '../Static/imgs/portfolio/Puffer.png'
import SwellNetwork from '../Static/imgs/portfolio/SwellNetwork.png'
import KelpDao from '../Static/imgs/portfolio/KelpDao.png'
import EigenPie from '../Static/imgs/portfolio/EigenPie.png'
import Babylon from '../Static/imgs/portfolio/Babylon.png'
import Pendle from '../Static/imgs/portfolio/Pendle.png'
import Huobi from '../Static/imgs/portfolio/HuobiLogo.png'
import Poloniex from '../Static/imgs/portfolio/Poloniex.png'
import EigenDA from '../Static/imgs/portfolio/EigenDA.png'

const list = [
    {
        type: 'Chains',
        desc: 'Total Value Locked',
        value: '$1.78B',
        children: [
            {
                icon: Ethereum,
                name: 'Ethereum',
                link: 'https://ethereum.org/en/'
            },
            {
                icon: Merlin,
                name: 'Merlin',
                link: 'https://merlinchain.io/'
            },
            {
                icon: Bouncebit,
                name: 'Bouncebit',
                link: 'https://bouncebit.io/'
            },
            {
                icon: Zklink,
                name: 'Zklink',
                link: 'https://zk.link/'
            },
            {
                icon: TRON,
                name: 'TRON',
                link: 'https://tron.network/'
            },
        ]
    },
    {
        type: 'Re/Staking',
        desc: 'Total Value Locked',
        value: '$2.9B',
        children: [
            {
                icon: Lido,
                name: 'Lido',
                link: 'https://lido.fi/'
            },
            {
                icon: EigenLayer,
                name: 'EigenLayer',
                link: 'https://www.eigenlayer.xyz/'
            },
            {
                icon: EtherFi,
                name: 'EtherFi',
                link: 'https://www.ether.fi/'
            },
            {
                icon: Puffer,
                name: 'Puffer',
                link: 'https://www.puffer.fi/'
            },
            {
                icon: SwellNetwork,
                name: 'Swell Network',
                link: 'https://www.swellnetwork.io/'
            },
            {
                icon: KelpDao,
                name: 'Kelp Dao',
                link: 'https://kelpdao.xyz/'
            },
            {
                icon: EigenPie,
                name: 'Eigen Pie',
                link: 'https://docs.eigenpiexyz.io/introduction/eigenpie'
            },
            {
                icon: Babylon,
                name: 'Babylon',
                link: 'https://babylonchain.io/'
            }
        ]
    },
    {
        type: 'Decentralized APPs',
        desc: '',
        value: '',
        children: [
            {
                icon: Pendle,
                name: 'Pendle',
                link: 'https://www.pendle.finance/'
            }
        ]
    },
    {
        type: 'CEX',
        desc: '',
        value: '',
        children: [
            {
                icon: Huobi,
                name: 'Huobi',
                link: 'https://www.htx.com/'
            },
            {
                icon: Poloniex,
                name: 'Poloniex',
                link: 'https://poloniex.com/'
            }
        ]
    },
    {
        type: 'Others',
        desc: '',
        value: '',
        children: [
            {
                icon: EigenDA,
                name: 'EigenDA',
                link: 'https://docs.eigenlayer.xyz/eigenda/overview'
            },
            {
                icon: Huobi,
                name: 'HTX Operator',
                link: 'https://www.htx.com/'
            },
            {
                icon: Huobi,
                name: 'HTX Earn Operator',
                link: 'https://www.htx.com/'
            },
            {
                icon: Huobi,
                name: 'HTX Liquid Staking Operator',
                link: 'https://www.htx.com/'
            }
        ]
    }
]

const Portfolio = () => {
    const [defaultValue, setDefaultValue] = React.useState('Chains')
    const currentObj = React.useMemo(() =>  {
        return list.filter((v) => v.type === defaultValue)[0]
    }, [defaultValue])
    return (
        <div id="portfolio" className="portfolio">
            <div className="portfolio-inner">
                <div className="title">Portfolio</div>
                <div className="content">
                    <div className="btn-group">
                    {list.map((v) => (
                        <button onClick={() => setDefaultValue(v.type)} key={v.type} className={[defaultValue === v.type ? 'active':  '']}>{v.type}</button>
                    ))}
                    </div>
                    <div className="detail">
                        <div className="left">
                            <div className="name">{currentObj.type}</div>
                            <div className="desc-box">
                                {currentObj.desc && <div className="desc">{currentObj.desc}</div>}
                                {currentObj.value && <div className="value">{currentObj.value}</div>}
                            </div>
                        </div>
                        <div className="right">
                            {currentObj.children.map((v) => (
                                <a key={v.name} href={v.link} target="_blank" rel="noreferrer" className="item">
                                    <img src={v.icon} alt="" />
                                    <span>{v.name}</span>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Portfolio