import * as React from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
import App from "./App";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
]);
