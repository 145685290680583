import React from "react";
import Lottie from "lottie-react";
import bannerJson from "../Static/json/home_bg.json";
import nextJson from "../Static/json/next-01.json";

const Home = () => {
  return (
    <div id="home" className="home">
      <div className="home-inner">
        <div className="left">
          <div className="left-inner">
          <div className="title">
            <span>Block</span>
            <span className="title-r">Layer</span>
          </div>
          <div className="desc">Securing the Future of Blockchains</div>
          <a href="/#contact">Contact Us</a>
          <div className="next">
            <Lottie
              animationData={nextJson}
              loop
              autoplay
              style={{ width: "48px" }}
            />
          </div>
          </div>
        </div>
        <div className="right">
        <Lottie
        animationData={bannerJson}
        loop
        autoplay
        style={{ height: "100vh", width: '100%' }}
      />
        </div>
        </div>
    </div>
  );
};
export default Home;
