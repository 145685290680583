import React from "react";
import logoIcon from "../Static/imgs/blocklayer-logo.svg";
import menuIcon from "../Static/imgs/h5/menu.svg";
import closeIcon from "../Static/imgs/h5/close.svg";
import { Anchor } from "antd";
const navList = [
  {
    key: "home",
    href: "#home",
    title: "Home",
  },
  {
    key: "about",
    href: "#about",
    title: "ABOUT US",
  },
  {
    key: "portfolio",
    href: "#portfolio",
    title: "PORTFOLIO",
  },
  {
    key: "contact",
    href: "#contact",
    title: "CONTACT US",
  },
];
const Header = (props) => {
  // const fullpageRef = useFullpage();
  // let location = useLocation();
  // const [current, setCurrent] = React.useState('home')
  // React.useEffect(() => {
  //     setCurrent(location.hash.slice(1))
  // }, [location])
  const [mNavShow, setMNavShow] = React.useState(false);
  const handleMenu = () => {
    setMNavShow(!mNavShow);
  };
  return (
    <div className="header">
      <div className="header-inner">
        <div className="header-left">
          <img src={logoIcon} alt="logo" />
          <span>BlockLayer</span>
        </div>
        <div className="header-right">
          <Anchor direction="horizontal" targetOffset={80} items={navList} />
        </div>
        <div className="header-right-h5">
          {mNavShow ? (
            <img src={closeIcon} onClick={handleMenu} alt="" />
          ) : (
            <img src={menuIcon} onClick={handleMenu} alt="" />
          )}
          <div
            className="m-nav-box"
            style={{ height: mNavShow ? "calc(100% - 64px)" : 0 }}
            onClick={() => setMNavShow(false)}
          >
            <div className="m-nav-inner">
            <Anchor targetOffset={64} items={navList} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
