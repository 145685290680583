import React from "react";
import { ConfigProvider } from 'antd';
// import FullpageWrapper from "./Components/FullpageWrapper";
import Header from "./Components/Header";
import Home from "./Components/Home";
import About from "./Components/About";
import Portfolio from "./Components/Portfolio";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
// import { FullPageProvider } from "./Components/FullpageContext";

function App() {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#000000' } }}>
      {/* <FullPageProvider> */}
      <div className="wrapper">
            <Header />
            <Home />
            <About />
            <Portfolio />
            <Contact />
            <Footer />
            </div>
      {/* <FullpageWrapper /> */}
      {/* </FullPageProvider> */}
    </ConfigProvider>
  );
}

export default App;
