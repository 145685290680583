import React from "react";
import ContactIcon from "../Static/imgs/Contact.svg";
import { Form, Input, Button, message as AntMessage } from "antd";
import axios from "axios";
import xss from "xss";

const requiredRule = { required: true, message: "*This field is required." };
const purifyRule = {
  message: "Please input valid character.",
  validator: async function (rule, value) {
    value = value || "";
    const filterStr = xss(value);
    if (value !== filterStr) {
      throw new Error("Please input valid character.");
    }
  },
};
const maxLengthRule = (length) => {
  return {
    message: `The input cannot exceed a maximum of ${length} characters.`,
    validator: async function (rule, value) {
      value = value || "";
      if (value.length > length) {
        throw new Error(
          `The input cannot exceed a maximum of ${length} characters.`
        );
      }
    },
  };
};

const Contact = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  return (
    <div id="contact" className="contactus">
      <div className="contactus-inner">
        <div className="left">
          <div className="title">Contact Us</div>
          <div className="desc">
            Please provide your details and queries and we will get back to you
            at the soonest.
          </div>
          <img src={ContactIcon} alt="" />
        </div>
        <div className="right">
          <div className="title">
            <h3>Contact Us</h3>
            <span>* Required</span>
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={async (values) => {
              setLoading(true);
              try {
                await form.validateFields();
                const {
                  data: { success, message },
                } = await axios.post("/api/submit/contact", {
                  ...values,
                });
                setLoading(false);
                if (success) {
                  form.resetFields();
                  AntMessage.success("Submit successfully.");
                } else {
                  AntMessage.error(message);
                }
              } catch (e) {
                setLoading(false);
                AntMessage.error(e.message);
              }
            }}
          >
            <Form.Item
              required
              rules={[requiredRule, purifyRule, maxLengthRule(200)]}
              label="1. Name*"
              name={"name"}
            >
              <Input />
            </Form.Item>

            <Form.Item
              required
              rules={[
                requiredRule,
                purifyRule,
                maxLengthRule(200),
                { type: "email", message: "Please input a valid email" },
              ]}
              label="2. Email*"
              name={"email"}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="message"
              rules={[requiredRule, purifyRule, maxLengthRule(1000)]}
              required
              label="3. Message*"
            >
              <Input.TextArea rows={3} />
            </Form.Item>

            <Form.Item className="submit">
              <Button type="submit" loading={loading} htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
