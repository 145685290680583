import React from "react";
import Lottie from "lottie-react";
import logoIcon from "../Static/imgs/logo.svg";
import xIcon from "../Static/imgs/X.svg";
import emailIcon from "../Static/imgs/icon_mail.svg";
import toTopIcon from "../Static/imgs/top.svg";
import footerJson from "../Static/json/BL_footer.json";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="footer">
      <div className="footer-inner">
        <div className="top">
          <div className="left">
            <div className="title">
              <img src={logoIcon} alt="" />
              <span>BlockLayer</span>
            </div>
            <div className="desc">
              Securing the <br />
              Future of Blockchains
            </div>
            <div className="contactbox">
              <div className="contact">
                <span>Follow Us</span>
                <a href="https://x.com/blocklayer_defi?s=21&t=aj2XNu_I13VGkakJS1_IAg" target="_blank" rel="noreferrer">
                  <img className="media" src={xIcon} alt="" />
                </a>
              </div>
              <div className="contact">
                <span>Contact Us</span>
                <a href="mailto:hello@blocklayer.network" className="emailbox">
                  <img className="email" src={emailIcon} alt="" />
                  <p>hello@blocklayer.network</p>
                </a>
              </div>
            </div>
          </div>
          <div className="right">
            <Lottie
              animationData={footerJson}
              loop
              autoplay
              style={{ width: "360px" }}
            />
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="footer-bottom-inner">
        <div className="copyright">© 2024 Blocklayer. All rights reserved.</div>
        <img src={toTopIcon} alt="" onClick={scrollToTop} />
      </div>
    </div>
  );
};

export default Footer;
