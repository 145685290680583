import React from "react";
import loadingIcon from "../Static/imgs/about-loading-icon.svg";
import loadingRotateIcon from "../Static/imgs/about-loading-rotate-icon.svg";
import Lottie from "lottie-react";
import nextJson from "../Static/json/next-02.json";

const list = [
  {
    label: "Participated Projects",
    count: "50+",
  },
  {
    label: "Countries",
    count: "12",
  },
  {
    label: "Total Asset Under Management",
    count: "$4B+",
  },
  {
    label: "Whitelisted Crypto Currencies",
    count: "30+",
  },
];

const About = () => {
  const itemRefs = React.useRef([]);
  const yearRef = React.useRef(null);

  React.useEffect(() => {
    const yearElement = yearRef.current
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: [0.5]
    });

    itemRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });
    if (yearElement) observer.observe(yearElement);

    return () => {
      itemRefs.current.forEach(ref => {
        if (ref) observer.unobserve(ref);
      });
      if (yearElement) observer.unobserve(yearElement);
    };
  }, []);
  return (
    <div id="about" className="about">
      <div className="about-inner">
        <ul className="top">
          {list.map((v, inx) => {
            return (
              <li key={v.label} className="list-box">
                <p className="title">{v.label}</p>
                <div className="content">
                  <img src={loadingIcon} className="content-img" alt="" />
                  <div
                    key={inx}
                    ref={el => itemRefs.current[inx] = el}
                    className="content-count"
                  >
                    {v.count}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <div className="bottom">
          <div className="left">
            <p className="title">Year Funded</p>
            <div className="content">
            <p ref={yearRef} className="bottom-count">2023</p>
            <img src={loadingRotateIcon} className="bottom-img" alt="" />
            <img src={loadingIcon} className="content-img" alt="" />
            </div>
          </div>
          <div className="right">
            <div className="right-top">
              <div className="label">About Us</div>
              <div className="desc">
                <span>
                Founded as a private group in 2021 and receiving funding in 2023. Blocklayer strives to support innovation and development across the blockchain and defi space.
                </span>
                <span>
                Our mission is to derisk projects and protocols with a set of stringent methodologies and processes, in order to provide a sense of assurance for the normal user to rely on when deploying their own person funds.
                </span>
                <span>
                Hence, having Blocklayer as participating partner would signal that appropriate due diligence and risk assessment has been performed.
                </span>
              </div>
            </div>
            <div className="right-bottom">
              <Lottie
                animationData={nextJson}
                loop
                autoplay
                style={{ height: "48px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
